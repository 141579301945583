/* account */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const GET_LOGIN_USER_ACCOUNT = 'GET_LOGIN_USER_ACCOUNT'
export const GET_FORGET_PASSWORD_TOKEN = 'GET_FORGET_PASSWORD_TOKEN'
export const RESET_ACCOUNT = 'RESET_ACCOUNT'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const GET_LOGIN_USER_INFO = 'GET_LOGIN_USER_INFO'
export const GET_LOGIN_USER_AUTHORITY = 'GET_LOGIN_USER_AUTHORITY'

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
