/**
 * @File: 用户权限
 */
// 产品权限
export const USERCENTER = 'USERCENTER'

export const USER_MANAGEMENT = 'usercenter_menu_management_user'
export const DEPARTMENT_MANAGEMENT = 'usercenter_menu_management_department'
export const COMMON_MANAGEMENT = 'usercenter_menu_management_common'
//用户管理的权限
export const USERLIST = 'usercenter_function_userlist'
export const USER_INVITELIST = 'usercenter_function_invitelist'
export const INSERTUSER = 'usercenter_function_insertuser'
// 部门管理的权限
export const DEPARTMENTLIST = 'usercenter_function_departmentlist'
export const INSERTDEPARTMENT = 'usercenter_function_insertdepartment'
export const DELETEDEPARTMENT = 'usercenter_function_deletedepartment'
export const UPDATEDEPARTMENT = 'usercenter_function_updatedepartment'
