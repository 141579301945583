/**
 * @File: 顶部标签栏tabsBar
 */
import initialStore from '@/constants/initialStore'
import sliderMenuData from '@/data/sliderMenu'
import { findAllIndex } from '@/utils/arrayUtil'
import * as types from '@/constants/tabsBar'

let tabCache = null // 缓存回调for循环的返回值

let fixedTabCache = [] // 缓存回调for循环的返回值
// 查找符合的tab
function getTab(data, url) {
  for (const index in data) {
    let path = data[index].path
    const pathArr = path.split('/').filter((i) => i)
    // 冒号开头代表路由这个位置是个参数，固定写法
    const regexp = /:[a-zA-Z]+/g
    const targets = path.match(regexp)
    const targetIndexes = findAllIndex(pathArr, targets)
    // 找到有冒号开头的 path
    if (targetIndexes.length > 0) {
      const urlArr = url.split('/').filter((i) => i)
      const strings = []
      targetIndexes.forEach((index) => {
        strings[index] = urlArr[index]
      })
      targetIndexes.forEach((index) => {
        pathArr.splice(index, 1)
        urlArr.splice(index, 1)
      })
      if (pathArr.join() === urlArr.join()) {
        targetIndexes.forEach((index) => {
          pathArr.splice(index, 0, strings[index])
        })
        path = `/${pathArr.join('/')}`
      }
    }
    if (path === url) {
      tabCache = { ...data[index], path }
      break
    } else if (data[index].children) {
      tabCache = getTab(data[index].children, url)
    }
  }
  return tabCache
}
// 查找固定tab
function getFixedTab(data) {
  for (const index in data) {
    if (data[index].hideClose) {
      if (
        !fixedTabCache.some((item) => {
          return item.path === data[index].path
        })
      ) {
        fixedTabCache.push(data[index])
      }
    } else if (data[index].children) {
      getFixedTab(data[index].children)
    }
  }
  return fixedTabCache
}

export default (state = initialStore.tabsBar, action) => {
  let visitedTabs = [...state.visitedTabs]
  let tabIndex = state.tabIndex
  const currentPath = action.currentPath
  const toJumpedIndex = action.toJumpedIndex
  const resultTabs = action.resultTabs
  // 如果 location里面传了参数的一定要把 state传过来
  const locationState = action.state
  let tab
  let fixedTabs

  switch (action.type) {
    case types.INIT_TABS_BAR: // 初始化tab路径数组
      tabIndex = 0 // 初始化index
      fixedTabs = getFixedTab(sliderMenuData)
      visitedTabs = [...fixedTabs]
      visitedTabs.forEach((item, index) => {
        if (item.path === currentPath) {
          tabIndex = index
        }
      })
      tab = getTab(sliderMenuData, currentPath)
      if (
        currentPath !== '/' &&
        currentPath !== '/home' &&
        !visitedTabs.some((item) => {
          return item.path === currentPath
        }) &&
        tab
      ) {
        visitedTabs.push(tab)
        tabIndex = visitedTabs.length - 1
      }
      if (locationState && visitedTabs[tabIndex]) {
        visitedTabs[tabIndex].state = locationState
      }
      return {
        ...state,
        visitedTabs,
        tabIndex,
      }
    case types.ADD_TAB: // 添加新tab
      tab = getTab(sliderMenuData, currentPath)
      const targetIndex = visitedTabs.findIndex((item) => {
        return item.name === tab.name
      })
      if (targetIndex === -1) {
        visitedTabs.push(tab)
      } else {
        visitedTabs[targetIndex].path = tab.path
      }
      tabIndex = visitedTabs.findIndex((item) => {
        return item.path === currentPath
      })
      if (tabIndex === -1) {
        tabIndex = visitedTabs.length - 1
      }
      if (locationState && visitedTabs[tabIndex]) {
        visitedTabs[tabIndex].state = locationState
      }
      return {
        ...state,
        visitedTabs,
        tabIndex,
      }
    case types.DEL_TAB: // 删除tab
      return {
        ...state,
        visitedTabs: resultTabs,
        tabIndex: toJumpedIndex,
      }
    default:
      return state
  }
}
