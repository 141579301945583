import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { AsyncLoginDemo, AsyncActivateAccount, AsyncRetrievePassword } from '@/pages'
import MainLayout from './MainLayout'

class RouterLayout extends Component {
  render() {
    return (
      <Switch>
        <Route exact path='/login' component={AsyncLoginDemo} />
        <Route exact path='/activateAccount' component={AsyncActivateAccount} />
        <Route exact path='/retrievePassword' component={AsyncRetrievePassword} />
        <Route component={MainLayout} />
      </Switch>
    )
  }
}

export default RouterLayout
