import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'moment/locale/zh-cn'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './assets/css/basic.css'
import './assets/css/form.css'
import './assets/css/property.css'
import './assets/css/resetNormalize.css'

moment.locale('zh-cn')

ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.unregister()
