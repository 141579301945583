/**
 * @Files: 账户信息
 */
import initialStore from '@/constants/initialStore'
import * as types from '../constants/account'

export default (state = initialStore.account, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        token: action.data.token
      }
    case types.GET_LOGIN_USER_ACCOUNT:
      return {
        ...state,
        account: action.data
      }
    case types.GET_FORGET_PASSWORD_TOKEN:
      return {
        ...state,
        account: action.data.account,
        token: action.data.token
      }
    case types.UPDATE_PASSWORD:
      return {
        ...state,
        isLogin: true,
        token: action.data.token,
      }
    case types.GET_LOGIN_USER_INFO:
      return {
        ...state,
        account: action.data,
      }
    case types.GET_LOGIN_USER_AUTHORITY:
      return {
        ...state,
        authority: action.data,
      }
    case types.RESET_ACCOUNT:
      return {
        isLogin: false,
      }
    default:
      return state
  }
}
