/**
 * @File: 应用主体区域布局及路由
 * @CR: @TODO By 大鹏：将 SliderMenu、GlobalHeader 发布组件库
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'

import SliderMenu from '@/pages/RouterLayout/SliderMenu'
import GlobalHeader from '@/pages/RouterLayout/GlobalHeader'
import TabsBar from './TabsBar'
import HomePage from '@/pages/HomePage'
import {
  AsyncUserManagement,
  AsyncDepartmentManagement,
  AsyncUserCreateAndDetail,
  AsyncUserCopyPermission
} from '@/pages'
import * as dimensions from '@/data/dimensions'
import styles from './MainLayout.module.css'

const { Content, Header } = Layout

class MainLayout extends Component {
  componentDidMount() {
    const { token, dispatch } = this.props
    if (!token) {
      dispatch(push('/login'))
    }
  }
  render() {
    const { isCollapsed } = this.props
    const mainOffsetLeft = isCollapsed ? dimensions.SLIDER_MENU_COLLAPSED_WIDTH : dimensions.SLIDER_MENU_WIDTH
    const layoutWidth = { marginLeft: mainOffsetLeft }

    return (
      <Layout className={styles.root}>
        <SliderMenu />
        <Layout style={layoutWidth} className={styles.main}>
          <Header className={styles.header}>
            <GlobalHeader />
            <TabsBar />
          </Header>
          <Content className={styles.content}>
            <Switch>
              <Route exact path="/userCenter/userManagement" component={AsyncUserManagement} />{/* 用户管理列表 */}
              <Route exact path="/userCenter/userManagement/create" component={AsyncUserCreateAndDetail} />{/* 新建用户 */}
              <Route exact path="/userCenter/userManagement/copy" component={AsyncUserCopyPermission} />{/* 批量复制权限 */}
              <Route exact path="/userCenter/userManagement/:id" component={AsyncUserCreateAndDetail} />{/* 用户详情 */}
              <Route exact path="/userCenter/departmentManagement" component={AsyncDepartmentManagement} />
              <Route path="/" component={HomePage} />{/* 首页 */}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default connect((state) => {
  const { isCollapsed } = state.sliderMenu
  const { token } = state.account
  return { isCollapsed, token }
})(MainLayout)
