/**
 * @File: reducers
 */
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loading from './loading'
import sliderMenu from './sliderMenu'
import tabsBar from './tabsBar'
import account from './account'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    loading,
    account,
    sliderMenu, // 全局菜单
    tabsBar,
  })
