/**
 * @File: 侧边栏导航sliderMenu
 */
import initialStore from '@/constants/initialStore'
import * as types from '@/constants/sliderMenu'

export default (state = initialStore.sliderMenu, action) => {
  switch (action.type) {
    case types.SAVE_SLIDER_MENU_COLLAPSED: // 保存侧边栏展开、收起
      return {
        ...state,
        isCollapsed: action.isCollapsed
      }
    default:
      return state
  }
}
