/**
 * @File: 全局加载loading
 */
import * as types from '@/constants/loading'

const initialState = {
  loading: false,
  loadingCount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1
      }

    case types.STOP_LOADING:
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1
      }

    case types.RESET_LOADING:
      return {
        ...state,
        loading: false,
        loadingCount: 0
      }
      
    default:
      return state
  }
}
