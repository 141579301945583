/**
 * @File: 请求封装
 */
import React from 'react'
import axios from 'axios'
import { biciNotification } from 'bici-transformers'
import { Modal, Icon } from 'antd'
import store from '../store'
import { logoutToken } from '@/actions/account'
import { startLoading, stopLoading } from '../actions/loading'

const { dispatch, getState } = store

function handleError(error) {
  // 處理錯誤
  if (error.response) {
    if (error.response.status === 403) {
      // window.location.href = '/login'
    } else if (error.response.data) {
      if (error.response.data.validationErrors) {
        biciNotification.error({
          message: `${error.response.data.validationErrors[0].error}`,
        })
      } else if (error.response.data.error) {
        biciNotification.error({ message: `${error.response.data.error}` })
      } else {
        biciNotification.error({ message: '系统升级中，请稍后再试' })
      }
    } else {
      biciNotification.error({
        message: `${error.response.statusText}` || '服务端出错',
      })
    }
  } else if (error.request) {
    biciNotification.error({ message: '服务端未响应' })
  } else if (error instanceof Object && error.hasOwnProperty('message')) {
    biciNotification.error({ message: `${error.message}` })
  } else {
    biciNotification.error({ message: '未知错误' })
  }
}
const instance = axios.create({
  // http://192.168.1.9:31202/remote/,http://192.168.1.9:31099/api/user/
  // baseURL: 'http://192.168.1.105:31099/api/user/',
  baseURL: '/api/user/',
  timeout: 300000,
  maxContentLength: Math.pow(1024, 2),
})
instance.interceptors.request.use(
  (config) => {
    let { isSilent = false } = config
    if (!isSilent) {
      dispatch(startLoading())
    }
    config.headers = {
      token: getState().account.token, // 不确定token存储位置
    }
    return config
  },
  (error) => {
    let {
      config: { isSilent = false },
    } = error
    if (!isSilent) {
      dispatch(stopLoading())
    }
    biciNotification.error({ message: '请求出错' })
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    const {
      config: { isSilent = false },
    } = response
    if (!isSilent) {
      dispatch(stopLoading())
    }
    const { data } = response
    if (data.code === 1000) {
      return data.data
    } else if (data.code === 4525) {
      window.location.href = '/login'
    } else if (data.code === 4507) {
      // 登录失效
      Modal.destroyAll()
      Modal.confirm({
        title: '登录超时，或权限被修改！',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />,
        onOk() {
          dispatch(logoutToken())
          window.location.href = '/login'
        },
      })
      return Promise.reject()
    } else {
      biciNotification.error({ message: data.msg })
      return Promise.reject(data.msg)
    }
  },
  (error) => {
    let {
      config: { isSilent = false },
    } = error
    if (!isSilent) {
      dispatch(stopLoading())
    }
    handleError(error)
    return Promise.reject(error)
  }
)

export default instance
