/**
 * @File: 顶部导航
 */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Modal, Layout, Icon, Button, Breadcrumb, Menu, Dropdown } from 'antd'
import { NoticeIcon } from 'ant-design-pro'
import { BiciDrawer, BiciInput, biciNotification } from 'bici-transformers'

import { postAmendPassword, postLogout } from '@/apis/account'
import { logoutToken } from '@/actions/account'
import { saveSliderMenuCollapsed } from '@/actions/sliderMenu'
import { addTab } from '@/actions/tabsBar'
import { findAllIndex } from '@/utils/arrayUtil'
import sliderMenuData from '@/data/sliderMenu'
import { tranRSA } from '@/utils/littleFunc'
import styles from './globalHeader.module.css'

function verifyForm(refs) {
  // 验证输入框是否有错误提示 可提为公共 需配合selfRefs
  let isError = false
  for (let key in refs) {
    if (refs[key].hint()) {
      isError = true
    }
  }
  return isError
}
// 设置 breadcrumbNameMap数据
function setMapData(data, breadcrumbNameMap) {
  data.forEach((item) => {
    if (item.children) {
      breadcrumbNameMap[item.path] = { name: item.name, isRoute: item.isRoute || false }
      setMapData(item.children, breadcrumbNameMap)
    } else {
      breadcrumbNameMap[item.path] = { name: item.name, isRoute: item.isRoute || false }
    }
  })
}

class GlobalHeader extends PureComponent {
  state = {
    account: this.props.account,
    visibleUserInfo: false,
    amendPassword: false,
    original: '',
    newpassword: '',
    repassword: '',
  }
  selfRefs = {}
  menu = (
    <Menu>
      <Menu.Item onClick={() => {
        this.setState({
          visibleUserInfo: true,
        })
      }}>个人信息
      </Menu.Item>
      <Menu.Item onClick={() => {
        this.setState({
          amendPassword: true,
        })
      }}>修改密码
      </Menu.Item>
      <Menu.Item onClick={() => { this.handlerLogout() }}>
        退出登录
      </Menu.Item>
    </Menu>
  )
  // 处理菜单栏展开、关闭
  handleSliderMenuTriggerToggle = () => {
    const { isCollapsed, dispatch } = this.props
    dispatch(saveSliderMenuCollapsed(!isCollapsed))
  }
  handlerOnChange = (name, value, infoHint) => {
    // 表单输入框的onChange
    this.setState({ [name]: value })
  }
  handlerAmendPassword = async () => {
    let result = verifyForm(this.selfRefs)
    if (result) {
      biciNotification.error({ message: '请填写密码' })
    } else {
      if (this.state.newpassword === this.state.repassword) {
        const { original, newpassword } = this.state
        const { account } = this.state.account.account
        const { dispatch } = this.props
        const beforePassword = tranRSA(original)
        const afterPassword = tranRSA(newpassword)
        const data = await postAmendPassword({ account, beforePassword, afterPassword })
        await this.setState({
          amendPassword: false,
          original: '',
          newpassword: '',
          repassword: '',
        })
        // 登录失效
        Modal.destroyAll()
        Modal.success({
          title: '修改成功，请重新登录！',
          okText: '确定',
          onOk() {
            dispatch(push('/login'))
            dispatch(logoutToken(data))
          },
        })

      } else {
        biciNotification.error({ message: '两次密码不一致' })
      }
    }
  }
  handlerLogout = async () => {
    const { token } = this.props.account
    const { dispatch } = this.props
    const data = await postLogout({ token })
    dispatch(push('/login'))
    dispatch(logoutToken(data))
  }
  // 点击面包屑跳转tab
  handleNav = (path) => {
    const { dispatch } = this.props
    dispatch(addTab(path))
  }
  render() {
    const { isCollapsed, location } = this.props
    const { original, newpassword, repassword } = this.state
    const { account } = this.state.account
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    const url = `/${pathSnippets.join('/')}`
    // 面包屑排除登录页面
    if (url === '/login') {
      return null
    }

    const breadcrumbNameMap = {}
    setMapData(sliderMenuData, breadcrumbNameMap)
    if (!breadcrumbNameMap[url]) {
      Object.keys(breadcrumbNameMap).forEach((path) => {
        // 处理思路：如果有冒号开头就新增一条数据在对象里面
        const pathArr = path.split('/').filter((i) => i)
        const urlArr = url.split('/').filter((i) => i)
        // 冒号开头代表路由这个位置是个参数，固定写法
        const regexp = /:[a-zA-Z]+/g
        const targets = path.match(regexp)
        const targetIndexes = findAllIndex(pathArr, targets)
        // 找到有冒号开头的 path
        const strings = []
        targetIndexes.forEach((index) => {
          strings[index] = urlArr[index]
        })
        targetIndexes.forEach((index) => {
          pathArr.splice(index, 1)
          urlArr.splice(index, 1)
        })
        if (targetIndexes.length > 0 && pathArr.join() === urlArr.join()) {
          const obj = breadcrumbNameMap[path]
          targetIndexes.forEach((index) => {
            pathArr.splice(index, 0, strings[index])
          })
          const newPath = `/${pathArr.join('/')}`
          breadcrumbNameMap[newPath] = obj
        }
      })
    }
    const breadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      return (
        <Breadcrumb.Item key={url}>
          {breadcrumbNameMap[url].isRoute ? (
            <Link onClick={() => this.handleNav(url)} to={url}>
              {breadcrumbNameMap[url].name}
            </Link>
          ) : (
            breadcrumbNameMap[url].name
          )}
        </Breadcrumb.Item>
      )
    })
    return (
      <div className={styles.root}>
        <div className="dpflex">
          <Icon
            type={isCollapsed ? 'menu-unfold' : 'menu-fold'}
            className={styles.trigger}
            onClick={this.handleSliderMenuTriggerToggle}
          />
          <Breadcrumb className={styles.breadcrumb}>{breadcrumbItems}</Breadcrumb>
        </div>
        <div className={styles.group}>
          <Dropdown
            overlay={this.menu}
            className={styles.userBox}
            placement="bottomRight"
          >
            <span className="ant-dropdown-link">
              <Icon type="user" className={styles.username} />
              <span className={styles.username}>{account ? account.name : ''}</span>
            </span>
          </Dropdown>
          <BiciDrawer
            width="sm"
            title="个人信息"
            placement="right"
            visible={this.state.visibleUserInfo}
            closable={false}
            onClose={() => {
              this.setState({
                visibleUserInfo: false,
              })
            }}
            footer={
              <div className={styles.cancel}>
                <Button
                  onClick={() => {
                    this.setState({
                      visibleUserInfo: false,
                    })
                  }}
                >
                  取消
                </Button>
              </div>
            }
          >
            <div className={styles.drawerWrap}>
              <BiciInput
                label="姓名"
                value={account ? account.name : ''}
                disabled
                verificationRule={this.handlerRepeteName}
              />
              <BiciInput
                label="所属部门"
                value={account && account.departmentList.length > 0 ? account.departmentList[0].name : ''}
                disabled
                verificationRule={this.handlerRepeteName}
              />
              <BiciInput
                label="账号（手机号）"
                value={account ? account.account : ''}
                disabled
                verificationRule={this.handlerRepeteName}
              />
            </div>
          </BiciDrawer>
          <BiciDrawer
            width="sm"
            title="修改密码"
            placement="right"
            visible={this.state.amendPassword}
            closable={false}
            onOk={this.handlerAmendPassword}
            onClose={() => {
              this.setState({
                amendPassword: false,
                original: '',
                newpassword: '',
                repassword: '',
              })
            }}
            footer
          >
            <div className={styles.drawerWrap}>
              <BiciInput
                label="原密码"
                isRequired
                type="password"
                value={original}
                regExp="^\S{6,18}$"
                ref={(el) => (this.selfRefs.verifyOriginalPasswordRef = el)}
                onChange={(e) => {
                  this.handlerOnChange('original', e.target.value)
                }}
              />
              <BiciInput
                label="新密码"
                isRequired
                type="password"
                value={newpassword}
                ref={(el) => (this.selfRefs.verifyNewPasswordRef = el)}
                placeholder="请输入6-18位字符密码"
                verificationTooltip="请输入6-18位字符密码"
                regExp="^\S{6,18}$"
                onChange={(e) => {
                  this.handlerOnChange('newpassword', e.target.value)
                }}
              />
              <BiciInput
                label="重复新密码"
                isRequired
                type="password"
                value={repassword}
                ref={(el) => (this.selfRefs.verifyRePasswordRef = el)}
                placeholder="请确认已输入的新密码"
                onChange={(e) => {
                  this.handlerOnChange('repassword', e.target.value)
                }}
              />
            </div>
          </BiciDrawer>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect((state) => {
    const { isCollapsed } = state.sliderMenu
    const { account } = state
    return { isCollapsed, account }
  })(GlobalHeader)
)
