/**
 * 公共处理小函数
 */
import { JSEncrypt } from 'jsencrypt'

/* 加密，sourceStr必须是字符串 */
export function getEncryption(sourceStr) {
  return window.btoa(window.encodeURIComponent(sourceStr))
}

/* 解密，getEncryption的反向 */
export function getDecryption(target) {
  return window.decodeURIComponent(window.atob(target))
}

// RSA加密
export const tranRSA = (param) => {
  let jsencrypt = new JSEncrypt()
  jsencrypt.setPublicKey('MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvJGaiiS3oLK9QXgm1jpzKe3g4jKRu0zXWqjaazh9NW13vdMcu3ctKT2+GqV9I7FMBgP69p9LX1hOXoSmagYB5Qku1Vrjx03mjnhcYaCleJzv7vksb8Rsx/Dd8pRCVoYvjsgawYB+oxnvlHKvk7d/XuHCOY02Tod21KpsBQ6Z9AwIDAQAB')
  return jsencrypt.encrypt(param)
}
