import React, { Component } from 'react'

class HomePage extends Component {
  render() {
    const tipsStyle = {
      paddingTop: '192px',
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '700',
    }
    return (
      <div className="page">
        <div style={tipsStyle}>欢迎来到用户中心</div>
      </div>
    )
  }
}

export default HomePage
