/**
 * @File: 左侧菜单数据
 */
const menuData = [
  {
    name: '用户中心',
    icon: 'global',
    path: 'userCenter',
    children: [
      {
        name: '用户管理',
        icon: 'team',
        path: 'userManagement',
        isRoute: true,
        children: [
          {
            name: '新建用户',
            path: 'create',
            isRoute: false
          },
          {
            name: '复制权限',
            path: 'copy',
            isRoute: false
          },
          {
            name: '用户详情',
            path: ':id',
            isRoute: false,
          },
        ]
      },
      {
        name: '部门管理',
        icon: 'project',
        path: 'departmentManagement',
        isRoute: true,
      },
    ],
  },
]
// 格式化侧边栏数据
function formatter(data, parentPath = '/') {
  return data.map((item) => {
    const result = {
      ...item,
      path: `${parentPath}${item.path}`,
    }
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`)
    }
    return result
  })
}

const sliderMenuData = formatter(menuData)

export default sliderMenuData
