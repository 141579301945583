/**
 * 登录
 */

import request from '@/utils/request'


/* 登录获取token */
export function postLogin(params) {
  return request.post('/login', params)
}
/* 邀请验证接口 */
export function postInviteCode(params) {
  return request.post('/active/invite/validate', params)
}
/* 注销登录接口 */
export function postLogout(params) {
  return request.post('/logout', params)
}
/* 密码更新接口 */
export function postUpdatePassword(params) {
  return request.post('/password/update', params)
}
/* 忘记密码获取验证码接口 */
export function postGetVerifyCode(params) {
  return request.post('/password/forget/verifycode', params)
}
/* 忘记密码验证账户与短信验证码接口 */
export function postVerifyCode(params) {
  return request.post('/password/forget/validate', params)
}
/* 验证是否有该用户 */
export function postVerifyAccount(params) {
  return request.post('/password/forget/validateacconut', params)
}

/* 获取用户信息 */
export function getUsersInfo() {
  return request.get('/management/login/info')
}

/* 获取用户权限 */
export function getAuthorityTree(params) {
  return request.get('/product/company/permissionlist', params)
}

/* 修改密码 */
export function postAmendPassword(params) {
  return request.post('/management/password/update', params)
}
