import Loadable from 'react-loadable'

const loading = () => null
const AsyncLoginDemo = Loadable({ loader: () => import('@/pages/Account/LoginDemo'), loading })
const AsyncActivateAccount = Loadable({ loader: () => import('@/pages/Account/ActivateAccount'), loading })
const AsyncRetrievePassword = Loadable({ loader: () => import('@/pages/Account/RetrievePassword'), loading })

// 用户管理
const AsyncUserManagement = Loadable({ loader: () => import('@/pages/UserManagement'), loading })
const AsyncUserCreateAndDetail = Loadable({ loader: () => import('@/pages/UserManagement/UserCreateAndDetail'), loading })
const AsyncUserCopyPermission = Loadable({ loader: () => import('@/pages/UserManagement/UserCopyPermission'), loading })

//部门管理 
const AsyncDepartmentManagement = Loadable({ loader: () => import('@/pages/DepartmentManagement'), loading })

export {
  AsyncLoginDemo,
  AsyncActivateAccount,
  AsyncRetrievePassword,
  AsyncUserManagement,
  AsyncDepartmentManagement,
  AsyncUserCreateAndDetail,
  AsyncUserCopyPermission
}
