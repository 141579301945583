/**
 * 登录
 */

import * as types from '@/constants/account'


/* 登录(获取token存到本地) */
export const loginSuccess = (data) => {
  return ({
    type: types.LOGIN_SUCCESS,
    data,
  })
}

/* 找回密码(获取用户账号存到本地) */
export const saveUserAccount = (data) => {
  return ({
    type: types.GET_LOGIN_USER_ACCOUNT,
    data,
  })
}

/* 激活账号（设置、找回）密码(获取找回密码时的一次性token) */
export const setForgetPasswordToken = (data) => {
  return ({
    type: types.GET_FORGET_PASSWORD_TOKEN,
    data,
  })
}

export const updatePassword = (data) => {
  return ({
    type: types.UPDATE_PASSWORD,
    data,
  })
}
/*  获取个人信息  */
export const getUserInfo = (data) => {
  return ({
    type: types.GET_LOGIN_USER_INFO,
    data,
  })
}
/*  获取权限  */
export const getAuthority = (data) => {
  return ({
    type: types.GET_LOGIN_USER_AUTHORITY,
    data,
  })
}
/*  登出  */
export const logoutToken = (data) => {
  return ({
    type: types.RESET_ACCOUNT,
    data,
  })
}

